<template>
    <div>
        <el-table
        :data="viewer.showFiles"
        size="small"
        style="width: 100%;"
        @row-click="handleRowClickEvent">
            <!-- <el-table-column
            type="selection"
            align="center"
            width="50">
            </el-table-column> -->
            <el-table-column
                label="文件名"
                width="400">
                <template slot-scope="scope">
                    <div class="img-box-tip">
                        <img :src="thumbIcon(scope.row)" alt="" :class="{'imgs': scope.row.suffix == 'pdf'}">
                        <span :title="scope.row.name">{{scope.row.name}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="文件类型"
                align="center">
                <template slot-scope="scope">
                    {{ scope.row.suffix || "文件夹" }}
                </template>
            </el-table-column>
            <el-table-column
                prop="ownerName"
                label="所有者"
                align="center">
            </el-table-column>
            <el-table-column
                label="大小"
                align="center">
                <template slot-scope="scope">
                    {{scope.row.size | byteSize}}
                </template>
            </el-table-column>
            <el-table-column
                label="创建时间"
                align="center">
                <template slot-scope="scope">
                    {{scope.row.createDate |date}}
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
                align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" icon="el-icon-delete" @click.stop="removeDocBtn(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            class="pagebox"
            @current-change="handleCurrentChange"
            :current-page="query.pageNum"
            :page-size="query.pageSize"
            layout="prev, pager, next" 
            :total="total">
        </el-pagination>

    </div>
</template>
<script>
import { shareAPI } from 'liankong-ui-api' 
import iconDefault from '@/assets/img/icon-def.png'
import iconUnknown from '@/assets/img/icon-unknown.png'
import iconImage from '@/assets/img/icon-picture.png'
import iconOFD from '@/assets/img/icon-ofd.png'
import iconAudio from '@/assets/img/icon-audio.png'
import iconWord from '@/assets/img/icon-word.png'
import iconPpt from '@/assets/img/icon-ppt.png'
import iconPdf from '@/assets/img/icon-pdf.png'
import iconExcl from '@/assets/img/icon-excl.png'
import iconMP3 from '@/assets/doc-icon/mp3.png'
import iconMP4 from '@/assets/doc-icon/mp4.png'
import iconZIP from '@/assets/doc-icon/zip.png'

//后缀映射
const suffixIconCache = {
  def: iconDefault,
  unknown: iconUnknown,

  png: iconImage,
  jpg: iconImage,
  jpeg: iconImage,
  bmp: iconImage,

  ofd: iconOFD,

  doc: iconWord,
  DOC: iconWord,
  docx: iconWord,

  mp3: iconMP3,
  mp4: iconMP4,
  zip: iconZIP,

  pdf: iconPdf,
  ppt:iconPpt,
  pptx:iconPpt,
  xls:iconExcl,
  xlsx:iconExcl
}

export default {
    data(){
        return{
            checkedLabel: '',
            viewerKey: 'my',
            query: {
                pageNum: 1,
                pageSize: 10
            },
            systemTag: [],
            newObj: {}
        }
    },
    inject: ["setQuery"],
    watch: {
        '$route'(newValue) {
            this.forArrayEvent(newValue.params.id);
        }
    },
    computed: {
        viewer() {
            return this.$store.state.doc.viewer[this.viewerKey] 
        },
        total() {
            return this.$store.state.doc.viewer[this.viewerKey].paging.total || 0
        },
        dict(){
            return this.$store.state.dict.dicts;
        }
    },
    mounted(){
        this.forArrayEvent(this.$route.params.id);
    },
    methods: {
        // 过滤出来当前选中的label
        forArrayEvent(id) {
            setTimeout(()=>{
                this.dict.doc.forEach(items=>{
                    if(items.id == id) {
                        this.checkedLabel = items.label;
                    }
                });
                this.searchListEvent();
            }, 200)
        },
        searchListEvent(){
            this.systemTag = [];
            this.systemTag.push(this.checkedLabel);
            this.newObj = {
                miji: [],
                tag: [],
                systemTag: this.checkedLabel == '全部' ? [] : this.systemTag
            }
            this.setQuery(this.newObj, this.query, false);
        },
        // 图标
        thumbIcon(doc){
            let iconKey = "unknown"
            if(suffixIconCache.hasOwnProperty(doc.suffix)){
                iconKey = doc.suffix
            }
            return suffixIconCache[iconKey] 
        },
        // 阅读
        handleRowClickEvent(row, column, event) {
            if (row.type == 0) {
                //打开文件夹
                // this.handleEnterFolder(row);
            } else {
                if(row.documentId != null){
                    row.i = row.documentId
                }
                this.$ebus.$emit('publicReader',{
                    shareId:row.shareId, 
                    id:row.documentId || row.id,
                    documentId: row.documentId,
                    name:row.name
                })
               
            }
        },
        // 删除
        removeDocBtn(row) {
            this.$store.dispatch("doc/checkedRemove", {
                vkey: this.viewerKey,
                id:[row.id]
            }).then((data) => {
                if(data) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.forArrayEvent(this.$route.params.id);
                } else {
                    this.$message.error('删除失败');
                }
            }) .catch(err => {
                this.$message.error(err);
            })
            
        },
        // 分页
        handleCurrentChange(num) {
            this.query.pageNum = num;
            this.searchListEvent();
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .img-box-tip {
    display: flex;
    align-items: center;
    img {
        width: 21px;
        height: 21px;
    }
    span {
        margin-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        white-space: nowrap;
        // -webkit-box-orient: vertical;
    }
    .imgs {
        border-radius: 4px;
    }
}
/deep/ .el-table {
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
            }
        }
    }
    .el-table__body-wrapper {
        tr td {
            border:none;
            padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
}
.pagebox {
    margin-top: 20px;
}
</style>